// white
$color-white--100: #ffffff;

// black
$color-black--100: #000000;
$color-black--80: #333333;
$color-black--60: #666666;
$color-black--40: #999999;
$color-black--20: #cccccc;
$color-black--10: #e6e6e6;
$color-black--05: #f2f2f2;
$color-black--03: #f7f7f7;

$color-black: (
  '100': $color-black--100,
  '80': $color-black--80,
  '60': $color-black--60,
  '40': $color-black--40,
  '20': $color-black--20,
  '10': $color-black--10,
  '05': $color-black--05,
  '03': $color-black--03,
);

// red
$color-red--100: #ff0000;
$color-red--80: #ff3333;
$color-red--60: #ff6666;
$color-red--40: #ff9999;
$color-red--20: #ffcccc;
$color-red--10: #ffe6e6;
$color-red--05: #fff2f2;
$color-red--03: #fff7f7;

$color-red: (
  '100': $color-red--100,
  '80': $color-red--80,
  '60': $color-red--60,
  '40': $color-red--40,
  '20': $color-red--20,
  '10': $color-red--10,
  '05': $color-red--05,
  '03': $color-red--03,
);

// violet
$color-violet--100: #990099;
$color-violet--80: #ad33ad;
$color-violet--60: #c266c2;
$color-violet--40: #d699d6;
$color-violet--20: #ebcceb;
$color-violet--10: #f5e6f5;
$color-violet--05: #faf2fa;
$color-violet--03: #fcf7fc;

$color-violet: (
  '100': $color-violet--100,
  '80': $color-violet--80,
  '60': $color-violet--60,
  '40': $color-violet--40,
  '20': $color-violet--20,
  '10': $color-violet--10,
  '05': $color-violet--05,
  '03': $color-violet--03,
);

// surrogate
$color-surrogate--100: #c7125c;
$color-surrogate--80: #d2417d;
$color-surrogate--60: #dd719e;
$color-surrogate--40: #e9a0be;
$color-surrogate--20: #f4d0df;
$color-surrogate--10: #fae8f0;
$color-surrogate--05: #fcf3f7;
$color-surrogate--03: #fdf8fa;

$color-surrogate: (
  '100': $color-surrogate--100,
  '80': $color-surrogate--80,
  '60': $color-surrogate--60,
  '40': $color-surrogate--40,
  '20': $color-surrogate--20,
  '10': $color-surrogate--10,
  '05': $color-surrogate--05,
  '03': $color-surrogate--03,
);

// turquoise
$color-turquoise--100: #009aa3;
$color-turquoise--80: #33aeb5;
$color-turquoise--60: #66c3c8;
$color-turquoise--40: #99d7da;
$color-turquoise--20: #ccebed;
$color-turquoise--10: #e6f5f6;
$color-turquoise--05: #f2fafa;
$color-turquoise--03: #f7fcfc;

$color-turquoise: (
  '100': $color-turquoise--100,
  '80': $color-turquoise--80,
  '60': $color-turquoise--60,
  '40': $color-turquoise--40,
  '20': $color-turquoise--20,
  '10': $color-turquoise--10,
  '05': $color-turquoise--05,
  '03': $color-turquoise--03,
);

// orange
$color-orange--100: #ee7100;
$color-orange--80: #f18d33;
$color-orange--60: #f5a966;
$color-orange--40: #f8c699;
$color-orange--20: #fce2cc;
$color-orange--10: #fdf1e6;
$color-orange--05: #fef8f2;
$color-orange--03: #fefbf7;

$color-orange: (
  '100': $color-orange--100,
  '80': $color-orange--80,
  '60': $color-orange--60,
  '40': $color-orange--40,
  '20': $color-orange--20,
  '10': $color-orange--10,
  '05': $color-orange--05,
  '03': $color-orange--03,
);

// green
$color-green--100: #64b32c;
$color-green--80: #83c256;
$color-green--60: #a2d180;
$color-green--40: #c2e1ab;
$color-green--20: #e0f0d5;
$color-green--10: #eff7ea;
$color-green--05: #f7fbf4;
$color-green--03: #fafdf9;

$color-green: (
  '100': $color-green--100,
  '80': $color-green--80,
  '60': $color-green--60,
  '40': $color-green--40,
  '20': $color-green--20,
  '10': $color-green--10,
  '05': $color-green--05,
  '03': $color-green--03,
);

// blue
$color-blue--100: #14459c;
$color-blue--80: #436bb0;
$color-blue--60: #7290c4;
$color-blue--40: #a1b5d7;
$color-blue--20: #d0daeb;
$color-blue--10: #e8edf5;
$color-blue--05: #f3f6fa;
$color-blue--03: #f8f9fc;

$color-blue: (
  '100': $color-blue--100,
  '80': $color-blue--80,
  '60': $color-blue--60,
  '40': $color-blue--40,
  '20': $color-blue--20,
  '10': $color-blue--10,
  '05': $color-blue--05,
  '03': $color-blue--03,
);

// yellow
$color-yellow--100: #ffcc00;
$color-yellow--80: #ffd633;
$color-yellow--60: #ffe066;
$color-yellow--40: #ffeb99;
$color-yellow--20: #fff5cc;
$color-yellow--10: #fffae6;
$color-yellow--05: #fffcf2;
$color-yellow--03: #fffdf7;

$color-yellow: (
  '100': $color-yellow--100,
  '80': $color-yellow--80,
  '60': $color-yellow--60,
  '40': $color-yellow--40,
  '20': $color-yellow--20,
  '10': $color-yellow--10,
  '05': $color-yellow--05,
  '03': $color-yellow--03,
);

$colors: (
  black: $color-black,
  red: $color-red,
  violet: $color-violet,
  surrogate: $color-surrogate,
  turquoise: $color-turquoise,
  orange: $color-orange,
  green: $color-green,
  blue: $color-blue,
  yellow: $color-yellow,
);

// company colors
$color-twitter: #00acee;
$color-linkedin: #0a66c2;
$color-xing-dark: #126567;
$color-xing-light: #afd400;
