@import '../../styles/index';

.base {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 0;

  @include media-from(sm) {
    grid-template-rows: max-content auto;
    column-gap: 2rem;
  }
}

.avatar {
  grid-column: 1;
  grid-row: 2;

  @include media-from(sm) {
    grid-row: 1 / 3;
  }
}

.avatar-no-cite {
  @include media-to(sm) {
    display: flex;
    justify-content: center;
    margin-right: 0;
    width: 100%;
  }
}

.cite {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: normal;
  font-size: $font-size-14;

  @include media-from(sm) {
    grid-column: 2 / 3;
    justify-content: start;
    font-size: $font-size-18;
  }

  strong,
  span {
    display: block;
  }

  strong {
    font-weight: $font-weight-bold;
    font-size: $font-size-20;
  }

  span {
    color: $color-black--60;
    font-size: $font-size-18;
  }
}

.quote {
  grid-column: 1 / 3;
  grid-row: 1;
  font-size: $font-size-20;
  line-height: 1.4 !important; // override richtext class

  @include media-from(sm) {
    grid-column: 2 / 3;
    grid-row: 1;
  }
}
